:root {
  /* Main colors */
  --primary-50-value: 236, 243, 255;
  --primary-100-value: 216, 230, 255;
  --primary-200-value: 166, 198, 255;
  --primary-300-value: 107, 161, 255;
  --primary-400-value: 57, 129, 255;
  --primary-500-value: 7, 98, 255;
  --primary-600-value: 6, 78, 204;
  --primary-700-value: 4, 58, 152;
  --primary-800-value: 3, 47, 122;
  --primary-900-value: 7, 16, 33;
  --secondary-50-value: 233, 250, 251;
  --secondary-100-value: 214, 246, 248;
  --secondary-200-value: 174, 236, 240;
  --secondary-300-value: 113, 222, 229;
  --secondary-400-value: 72, 213, 222;
  --secondary-500-value: 52, 208, 218;
  --secondary-600-value: 45, 170, 178;
  --secondary-700-value: 36, 114, 119;
  --secondary-800-value: 30, 76, 79;
  --secondary-900-value: 13, 27, 28;
  --tertiary-50-value: 238, 231, 254;
  --tertiary-100-value: 237, 229, 253;
  --tertiary-200-value: 219, 203, 252;
  --tertiary-300-value: 192, 165, 249;
  --tertiary-400-value: 165, 126, 247;
  --tertiary-500-value: 147, 96, 255;
  --tertiary-600-value: 118, 77, 204;
  --tertiary-700-value: 89, 58, 154;
  --tertiary-800-value: 70, 46, 122;
  --tertiary-900-value: 26, 22, 34;
  /* Feedback Colors */
  --success-50-value: 238, 251, 241;
  --success-100-value: 222, 248, 228;
  --success-200-value: 179, 238, 192;
  --success-300-value: 129, 228, 151;
  --success-400-value: 86, 218, 115;
  --success-500-value: 44, 209, 80;
  --success-600-value: 35, 167, 64;
  --success-700-value: 26, 125, 48;
  --success-800-value: 21, 100, 38;
  --success-900-value: 0, 43, 9;
  --warning-50-value: 255, 250, 238;
  --warning-100-value: 254, 245, 221;
  --warning-200-value: 254, 232, 176;
  --warning-300-value: 253, 217, 124;
  --warning-400-value: 252, 204, 80;
  --warning-500-value: 251, 191, 36;
  --warning-600-value: 201, 153, 29;
  --warning-700-value: 150, 114, 21;
  --warning-800-value: 120, 91, 17;
  --warning-900-value: 91, 69, 13;
  --error-50-value: 255, 242, 243;
  --error-100-value: 255, 229, 230;
  --error-200-value: 255, 196, 198;
  --error-300-value: 255, 158, 161;
  --error-400-value: 255, 125, 129;
  --error-500-value: 255, 92, 97;
  --error-600-value: 204, 74, 78;
  --error-700-value: 152, 55, 58;
  --error-800-value: 122, 44, 46;
  --error-900-value: 92, 33, 35;
  /* Neutral colors */
  --neutral-50-value: 238, 239, 239;
  --neutral-100-value: 222, 223, 223;
  --neutral-200-value: 179, 181, 182;
  --neutral-300-value: 129, 132, 135;
  --neutral-400-value: 86, 90, 93;
  --neutral-500-value: 44, 49, 53;
  --neutral-600-value: 35, 39, 42;
  --neutral-700-value: 26, 29, 32;
  --neutral-800-value: 21, 23, 25;
  --neutral-900-value: 16, 18, 19;
  /* Base colors */
  --base-white-value: 255, 255, 255;
  --base-black-value: 0, 0, 0;
  /* Main colors */
  --primary-50: rgb(var(--primary-50-value));
  --primary-100: rgb(var(--primary-100-value));
  --primary-200: rgb(var(--primary-200-value));
  --primary-300: rgb(var(--primary-300-value));
  --primary-400: rgb(var(--primary-400-value));
  --primary-500: rgb(var(--primary-500-value));
  --primary-600: rgb(var(--primary-600-value));
  --primary-700: rgb(var(--primary-700-value));
  --primary-800: rgb(var(--primary-800-value));
  --primary-900: rgb(var(--primary-900-value));
  --secondary-50: rgb(var(--secondary-50-value));
  --secondary-100: rgb(var(--secondary-100-value));
  --secondary-200: rgb(var(--secondary-200-value));
  --secondary-300: rgb(var(--secondary-300-value));
  --secondary-400: rgb(var(--secondary-400-value));
  --secondary-500: rgb(var(--secondary-500-value));
  --secondary-600: rgb(var(--secondary-600-value));
  --secondary-700: rgb(var(--secondary-700-value));
  --secondary-800: rgb(var(--secondary-800-value));
  --secondary-900: rgb(var(--secondary-900-value));
  --tertiary-50: rgb(var(--tertiary-50-value));
  --tertiary-100: rgb(var(--tertiary-100-value));
  --tertiary-200: rgb(var(--tertiary-200-value));
  --tertiary-300: rgb(var(--tertiary-300-value));
  --tertiary-400: rgb(var(--tertiary-400-value));
  --tertiary-500: rgb(var(--tertiary-500-value));
  --tertiary-600: rgb(var(--tertiary-600-value));
  --tertiary-700: rgb(var(--tertiary-700-value));
  --tertiary-800: rgb(var(--tertiary-800-value));
  --tertiary-900: rgb(var(--tertiary-900-value));
  /* Feedback Colors */
  --success-50: rgb(var(--success-50-value));
  --success-100: rgb(var(--success-100-value));
  --success-200: rgb(var(--success-200-value));
  --success-300: rgb(var(--success-300-value));
  --success-400: rgb(var(--success-400-value));
  --success-500: rgb(var(--success-500-value));
  --success-600: rgb(var(--success-600-value));
  --success-700: rgb(var(--success-700-value));
  --success-800: rgb(var(--success-800-value));
  --success-900: rgb(var(--success-900-value));
  --warning-50: rgb(var(--warning-50-value));
  --warning-100: rgb(var(--warning-100-value));
  --warning-200: rgb(var(--warning-200-value));
  --warning-300: rgb(var(--warning-300-value));
  --warning-400: rgb(var(--warning-400-value));
  --warning-500: rgb(var(--warning-500-value));
  --warning-600: rgb(var(--warning-600-value));
  --warning-700: rgb(var(--warning-700-value));
  --warning-800: rgb(var(--warning-800-value));
  --warning-900: rgb(var(--warning-900-value));
  --error-50: rgb(var(--error-50-value));
  --error-100: rgb(var(--error-100-value));
  --error-200: rgb(var(--error-200-value));
  --error-300: rgb(var(--error-300-value));
  --error-400: rgb(var(--error-400-value));
  --error-500: rgb(var(--error-500-value));
  --error-600: rgb(var(--error-600-value));
  --error-700: rgb(var(--error-700-value));
  --error-800: rgb(var(--error-800-value));
  --error-900: rgb(var(--error-900-value));
  /* Neutral colors */
  --neutral-50: rgb(var(--neutral-50-value));
  --neutral-100: rgb(var(--neutral-100-value));
  --neutral-200: rgb(var(--neutral-200-value));
  --neutral-300: rgb(var(--neutral-300-value));
  --neutral-400: rgb(var(--neutral-400-value));
  --neutral-500: rgb(var(--neutral-500-value));
  --neutral-600: rgb(var(--neutral-600-value));
  --neutral-700: rgb(var(--neutral-700-value));
  --neutral-800: rgb(var(--neutral-800-value));
  --neutral-900: rgb(var(--neutral-900-value));
  /* Base colors */
  --base-white: rgb(var(--base-white-value));
  --base-black: rgb(var(--base-black-value));
  /* Main colors */
  --creator-primary-50-value: 253, 241, 224;
  --creator-primary-100-value: 255, 232, 156;
  --creator-primary-500-value: 255, 201, 52;
  --creator-primary-600-value: 179, 143, 58;
  --creator-primary-700-value: 122, 90, 32;
  --creator-primary-50: rgb(var(--creator-primary-50-value));
  --creator-primary-100: rgb(var(--creator-primary-100-value));
  --creator-primary-500: rgb(var(--creator-primary-500-value));
  --creator-primary-600: rgb(var(--creator-primary-600-value));
  --creator-primary-700: rgb(var(--creator-primary-700-value));
  /* Main colors */
  --ai-primary-50-value: 255, 246, 226;
  --ai-primary-100-value: 255, 233, 191;
  --ai-primary-200-value: 255, 210, 138;
  --ai-primary-300-value: 255, 187, 85;
  --ai-primary-400-value: 255, 165, 43;
  --ai-primary-500-value: 255, 144, 0;
  --ai-primary-600-value: 212, 118, 11;
  --ai-primary-700-value: 154, 106, 37;
  --ai-primary-800-value: 102, 84, 55;
  --ai-primary-900-value: 51, 43, 12;
  --ai-primary-50: rgb(var(--ai-primary-50-value));
  --ai-primary-100: rgb(var(--ai-primary-100-value));
  --ai-primary-200: rgb(var(--ai-primary-200-value));
  --ai-primary-300: rgb(var(--ai-primary-300-value));
  --ai-primary-400: rgb(var(--ai-primary-400-value));
  --ai-primary-500: rgb(var(--ai-primary-500-value));
  --ai-primary-600: rgb(var(--ai-primary-600-value));
  --ai-primary-700: rgb(var(--ai-primary-700-value));
  --ai-primary-800: rgb(var(--ai-primary-800-value));
  --ai-primary-900: rgb(var(--ai-primary-900-value));
}

/* Tailwind */

*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}

.eva-fade-in-transition {
  transition: opacity 0.4s ease-out;
  opacity: 0;
}

.eva-fade-out-transition {
  transition: opacity 0.4s ease-out;
  opacity: 1;
}

.eva-fade-out-row-animation {
  animation: hide-row 600ms 100ms cubic-bezier(0.38, 0.97, 0.56, 0.76) forwards;
  opacity: 1;
  transform: translateY(0);
}

@keyframes hide-row {
  100% {
    opacity: 0;
    transform: translateY(-20%);
    transform-origin: top center;
  }
}

.eva-fade-in-row-animation {
  animation: show-row 600ms 100ms cubic-bezier(0.38, 0.97, 0.56, 0.76) forwards;
  opacity: 0;
  transform: translateY(-20%);
  transform-origin: top center;
}

.eva-fade-in-row-with-indicator-animation {
  animation: show-row 600ms 100ms cubic-bezier(0.38, 0.97, 0.56, 0.76) forwards, remove-left-border 600ms 3s cubic-bezier(0.38, 0.97, 0.56, 0.76) forwards;
  opacity: 0;
  border-left: 2px;
  border-left-style: solid;
  border-left-color: #2CD150;
  transform: translateY(-20%);
  transform-origin: top center;
}

.eva-border-left-indicator-animation {
  animation: remove-left-border 600ms 3s cubic-bezier(0.38, 0.97, 0.56, 0.76) forwards;
  opacity: 0;
  border-left: 2px;
  border-left-style: solid;
  border-left-color: #2CD150;
}

@keyframes show-row {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes remove-left-border {
  100% {
    border-left: 0;
    border-left-style: solid;
    border-left-color: transparent !important;
  }
}

.eva-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.eva-hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.e-horizontal-scroll::-webkit-scrollbar {
  height: 0.5rem;
}

.e-vertical-scroll::-webkit-scrollbar {
  width: 0.5rem;
}

.eva-calendar .rbc-time-view {
  border: 0;
}

.eva-calendar .rbc-time-view .rbc-time-content::-webkit-scrollbar {
  width: 0.5rem;
}

.eva-calendar .rbc-time-view .rbc-time-content::-webkit-scrollbar-track {
  background-color: #1A1D20;
}

.eva-calendar .rbc-time-view .rbc-time-content::-webkit-scrollbar-thumb {
  background-color: #565A5D;
  border-radius: 0.5rem;
}

.eva-calendar .rbc-time-view .rbc-time-content {
  border-top: 1px solid #2C3135;
  borderleft: 0;
  border-right: 0;
  border-bottom: 1px solid #2C3135;
}

.eva-calendar .rbc-time-view .rbc-time-content > * + * > * {
  border-left: 1px solid #2C3135;
}

.eva-calendar .rbc-time-view .rbc-time-content .rbc-time-gutter {
  background-color: #101213;
  color: #818487;
  border-right: 1px solid #2C3135;
}

.eva-calendar .rbc-time-view .rbc-time-content .rbc-time-gutter .rbc-timeslot-group .rbc-time-slot {
  font-size: 0.875rem;
  padding: 0.5rem;
}

.eva-calendar .rbc-time-view .rbc-time-content .rbc-time-gutter .rbc-timeslot-group .rbc-time-slot .rbc-label {
  padding: 0;
  font-weight: 600;
  padding-left: 0.5rem;
}

.eva-calendar .rbc-time-view .rbc-time-content .rbc-time-column {
  border-bottom: 1px solid #2C3135;
}

.eva-calendar .rbc-time-view .rbc-time-content .rbc-time-column .rbc-timeslot-group {
  min-height: 6rem;
  border-bottom: 1px solid #2C3135;
}

.eva-calendar .rbc-time-view .rbc-time-content .rbc-time-column .rbc-timeslot-group .rbc-timeslot-slot {
}

.eva-calendar .rbc-time-view .rbc-time-content .rbc-time-column .rbc-events-container {
  margin-right: 0;
}

.eva-calendar .rbc-time-view .rbc-time-content .rbc-time-column .rbc-events-container .rbc-event {
  border: 0;
  background-color: transparent;
  overflow: visible;
}

.eva-calendar .rbc-time-view .rbc-time-content .rbc-time-column .rbc-events-container .rbc-event .rbc-event-label {
  display: none;
}

.eva-calendar .rbc-time-view .rbc-time-content .rbc-day-slot .rbc-timeslot-group .rbc-time-slot {
  border-top: 1px dashed #2C3135;
}

.eva-calendar .rbc-time-view .rbc-time-content .rbc-day-slot .rbc-timeslot-group .rbc-time-slot:first-child {
  border-top: 0;
}

.eva-calendar .rbc-time-view .rbc-time-content .rbc-day-slot.rbc-today {
  background-color: transparent;
}

.eva-calendar .rbc-time-view .rbc-time-header .rbc-time-header-gutter {
  border-right: 1px solid #2C3135;
  border-bottom: 1px solid #2C3135;
  background-color: #101213 !important;
}

.eva-calendar .rbc-time-view .rbc-time-header .rbc-allday-cell {
  display: none;
}

.eva-calendar .rbc-time-view .rbc-time-header .rbc-time-header-content {
  border-left: 1px solid #2C3135;
}

.eva-calendar .rbc-time-view .rbc-time-header .rbc-time-header-content > .rbc-row {
  border-bottom: 1px solid #2C3135;
}

.eva-calendar .rbc-time-view .rbc-time-header .rbc-time-header-content > .rbc-row .rbc-header {
  color: #FFFFFF;
  text-align: left;
  font-weight: 300;
  border-bottom: 1px solid #2C3135;
  padding: 8px 12px;
}

.eva-calendar .rbc-time-view .rbc-time-header .rbc-time-header-content > .rbc-row .rbc-header.rbc-today {
  color: #6BA1FF;
  background-color: transparent;
}

.eva-calendar .rbc-time-view .rbc-time-header .rbc-time-header-content > .rbc-row .rbc-header .rbc-button-link span[role="columnheader"] {
  text-transform: capitalize;
}

.eva-calendar .rbc-time-view .rbc-time-header .rbc-time-header-content > .rbc-row .rbc-header + .rbc-header {
  border-left: 1px solid #2C3135;
}

.eva-calendar .rbc-time-view .rbc-time-header.rbc-overflowing {
  border-right: 1px solid #2C3135;
}

.eva-calendar .rbc-time-view-resources .rbc-time-header .rbc-time-header-content .rbc-row-resource .rbc-header {
  padding: 0;
  min-width: 180px;
}

.eva-calendar .rbc-time-view-resources .rbc-day-slot {
  min-width: 180px;
}

.eva-calendar .rbc-month-view {
  border-top: 0;
  border-left: 1px solid #2C3135;
  border-right: 1px solid #2C3135;
  border-bottom: 1px solid #2C3135;
}

.eva-calendar .rbc-month-view .rbc-month-header .rbc-header {
  color: #FFFFFF;
  text-align: left;
  font-weight: 300;
  padding: 8px 12px;
  border-bottom: 1px solid #2C3135;
}

.eva-calendar .rbc-month-view .rbc-month-header .rbc-header span[role="columnheader"] {
  text-transform: capitalize;
  font-weight: 600;
}

.eva-calendar .rbc-month-view .rbc-month-header .rbc-header + .rbc-header {
  border-left: 1px solid #2C3135;
}

.eva-calendar .rbc-month-view .rbc-month-row .rbc-row-bg .rbc-day-bg.rbc-today {
  background-color: transparent;
}

.eva-calendar .rbc-month-view .rbc-month-row .rbc-row-bg .rbc-day-bg.rbc-off-range-bg {
  background: #1A1D20;
}

.eva-calendar .rbc-month-view .rbc-month-row .rbc-row-bg .rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #2C3135;
}

.eva-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-date-cell {
  color: #FFFFFF;
}

.eva-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-date-cell .rbc-button-link {
  position: relative;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}

.eva-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-date-cell.rbc-now {
  color: #101213;
}

.eva-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-date-cell.rbc-now .rbc-button-link {
  font-weight: 700;
}

.eva-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-date-cell.rbc-now .rbc-button-link::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  left: -5px;
  padding: 12px 16px;
  border-radius: 0.375rem;
  background: #FF5C61;
}

.eva-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-event {
  background-color: transparent;
}

.eva-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-event:focus {
  outline: 0 none;
}

.eva-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-row-segment .rbc-show-more {
  color: #6BA1FF;
  background-color: transparent;
  font-size: 0.875rem;
  padding: 2px 5px;
}

.eva-calendar .rbc-month-view .rbc-month-row + .rbc-month-row {
  border-top: 1px solid #2C3135;
}

.eva-day-picker {
  color: #FFFFFF;
}

.eva-day-picker .rdp {
  --rdp-cell-size: 40px;
  --rdp-accent-color: #6BA1FF;
  --rdp-background-color: transparent;
  --rdp-accent-color-dark: #3981FF;
  --rdp-background-color-dark: #043A98;
  --rdp-outline: 2px solid var(--rdp-accent-color);
  --rdp-outline-selected: 3px solid var(--rdp-accent-color);
}

.eva-day-picker .rdp .rdp-months .rdp-month .rdp-caption .rdp-caption_label {
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
}

.eva-day-picker .rdp .rdp-months .rdp-month .rdp-caption .rdp-nav {
  color: #6BA1FF;
}

.eva-day-picker .rdp .rdp-months .rdp-month .rdp-caption .rdp-nav .rdp-nav_button svg {
  width: 12px;
  height: 12px;
}

.eva-day-picker .rdp .rdp-months .rdp-month .rdp-table .rdp-head .rdp-head_row .rdp-head_cell span:not(.rdp-vhidden) {
  text-transform: capitalize;
}

.eva-day-picker .rdp .rdp-months .rdp-month .rdp-table .rdp-tbody .rdp-row .rdp-cell .rdp-day {
  color: #FFFFFF;
  border-radius: 0.5rem;
}

.eva-day-picker .rdp .rdp-months .rdp-month .rdp-table .rdp-tbody .rdp-row .rdp-cell .rdp-day:hover {
  background-color: #2C3135;
}

.eva-day-picker .rdp .rdp-months .rdp-month .rdp-table .rdp-tbody .rdp-row .rdp-cell .rdp-day:active {
  background-color: #23272A;
}

.eva-day-picker .rdp .rdp-months .rdp-month .rdp-table .rdp-tbody .rdp-row .rdp-cell .rdp-day.rdp-day_outside {
  opacity: 1;
  color: #565A5D;
  border-radius: 0.5rem;
}

.eva-day-picker .rdp .rdp-months .rdp-month .rdp-table .rdp-tbody .rdp-row .rdp-cell .rdp-day.rdp-day_outside:hover {
  color: #818487;
  background-color: #2C3135;
}

.eva-day-picker .rdp .rdp-months .rdp-month .rdp-table .rdp-tbody .rdp-row .rdp-cell .rdp-day.rdp-day_outside:active {
  color: #818487;
  background-color: #23272A;
}

.eva-day-picker .rdp .rdp-months .rdp-month .rdp-table .rdp-tbody .rdp-row .rdp-cell .rdp-day.rdp-day_today {
  background-color: #6BA1FF;
  color: #1A1D20;
  border-radius: 0.5rem;
}

.eva-day-picker .rdp .rdp-months .rdp-month .rdp-table .rdp-tbody .rdp-row .rdp-cell .rdp-day.rdp-day_today:hover {
  color: #1A1D20;
  background-color: #0762FF;
}

.eva-day-picker .rdp .rdp-months .rdp-month .rdp-table .rdp-tbody .rdp-row .rdp-cell .rdp-day.rdp-day_today:active {
  color: #1A1D20;
  background-color: #064ECC;
}

.eva-day-picker .rdp .rdp-months .rdp-month .rdp-table .rdp-tbody .rdp-row .rdp-cell .rdp-day.rdp-day_selected {
  background-color: #A6C6FF;
  color: #23272A;
  border-radius: 0.5rem;
}

.eva-day-picker .rdp .rdp-months .rdp-month .rdp-table .rdp-tbody .rdp-row .rdp-cell .rdp-day.rdp-day_selected:hover {
  color: #23272A;
  background-color: #6BA1FF;
}

.eva-day-picker .rdp .rdp-months .rdp-month .rdp-table .rdp-tbody .rdp-row .rdp-cell .rdp-day.rdp-day_selected:active {
  color: #1A1D20;
  background-color: #3981FF;
}

.eva-richtextarea {
  height: 100%;
}

.eva-richtextarea .tox {
  border: 0;
  border-style: hidden;
  border-color: transparent;
}

.eva-richtextarea .tox .tox-edit-area__iframe {
  background-color: transparent;
}

.e-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.e-pointer-events-none {
  pointer-events: none;
}

.e-pointer-events-auto {
  pointer-events: auto;
}

.e-visible {
  visibility: visible;
}

.e-invisible {
  visibility: hidden;
}

.e-static {
  position: static;
}

.e-fixed {
  position: fixed;
}

.e-absolute {
  position: absolute;
}

.e-relative {
  position: relative;
}

.e-sticky {
  position: sticky;
}

.e-inset-0 {
  inset: 0px;
}

.-e-bottom-1 {
  bottom: -0.25rem;
}

.-e-left-1 {
  left: -0.25rem;
}

.-e-left-1\.5 {
  left: -0.375rem;
}

.-e-left-2 {
  left: -0.5rem;
}

.-e-right-0 {
  right: -0px;
}

.-e-right-2 {
  right: -0.5rem;
}

.-e-top-1 {
  top: -0.25rem;
}

.-e-top-10 {
  top: -2.5rem;
}

.-e-top-2 {
  top: -0.5rem;
}

.e-bottom-0 {
  bottom: 0px;
}

.e-bottom-1 {
  bottom: 0.25rem;
}

.e-bottom-1\/10 {
  bottom: 10%;
}

.e-bottom-10 {
  bottom: 2.5rem;
}

.e-bottom-14 {
  bottom: 3.5rem;
}

.e-bottom-2 {
  bottom: 0.5rem;
}

.e-bottom-28 {
  bottom: 7rem;
}

.e-bottom-3 {
  bottom: 0.75rem;
}

.e-bottom-4 {
  bottom: 1rem;
}

.e-bottom-7 {
  bottom: 1.75rem;
}

.e-bottom-8 {
  bottom: 2rem;
}

.e-left-0 {
  left: 0px;
}

.e-left-1 {
  left: 0.25rem;
}

.e-left-1\/03 {
  left: 3%;
}

.e-left-1\/2 {
  left: 50%;
}

.e-left-1\/4 {
  left: 25%;
}

.e-left-2 {
  left: 0.5rem;
}

.e-left-3 {
  left: 0.75rem;
}

.e-left-52 {
  left: 13rem;
}

.e-left-7 {
  left: 1.75rem;
}

.e-left-8 {
  left: 2rem;
}

.e-right-0 {
  right: 0px;
}

.e-right-1 {
  right: 0.25rem;
}

.e-right-1\/03 {
  right: 3%;
}

.e-right-1\/05 {
  right: 5%;
}

.e-right-1\/10 {
  right: 10%;
}

.e-right-2 {
  right: 0.5rem;
}

.e-right-3 {
  right: 0.75rem;
}

.e-right-4 {
  right: 1rem;
}

.e-right-8 {
  right: 2rem;
}

.e-right-\[3px\] {
  right: 3px;
}

.e-top-0 {
  top: 0px;
}

.e-top-1 {
  top: 0.25rem;
}

.e-top-1\/2 {
  top: 50%;
}

.e-top-10 {
  top: 2.5rem;
}

.e-top-2 {
  top: 0.5rem;
}

.e-top-3 {
  top: 0.75rem;
}

.e-top-5 {
  top: 1.25rem;
}

.e-top-6 {
  top: 1.5rem;
}

.e-top-8 {
  top: 2rem;
}

.e-top-\[3px\] {
  top: 3px;
}

.e-top-full {
  top: 100%;
}

.-e-z-1 {
  z-index: -1;
}

.e-z-0 {
  z-index: 0;
}

.e-z-1 {
  z-index: 1;
}

.e-z-10 {
  z-index: 10;
}

.e-z-20 {
  z-index: 20;
}

.e-z-30 {
  z-index: 30;
}

.e-z-40 {
  z-index: 40;
}

.e-z-50 {
  z-index: 50;
}

.e-col-span-3 {
  grid-column: span 3 / span 3;
}

.e-m-0\.5 {
  margin: 0.125rem;
}

.e-m-1 {
  margin: 0.25rem;
}

.e-m-2 {
  margin: 0.5rem;
}

.e-m-6 {
  margin: 1.5rem;
}

.e-mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.e-mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.e-mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.e-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.e-mx-px {
  margin-left: 1px;
  margin-right: 1px;
}

.e-my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.e-my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.e-my-2\.5 {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.e-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.e-my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.e-mb-0\.5 {
  margin-bottom: 0.125rem;
}

.e-mb-1 {
  margin-bottom: 0.25rem;
}

.e-mb-2 {
  margin-bottom: 0.5rem;
}

.e-mb-20 {
  margin-bottom: 5rem;
}

.e-mb-28 {
  margin-bottom: 7rem;
}

.e-mb-3 {
  margin-bottom: 0.75rem;
}

.e-mb-4 {
  margin-bottom: 1rem;
}

.e-mb-6 {
  margin-bottom: 1.5rem;
}

.e-mb-8 {
  margin-bottom: 2rem;
}

.e-ml-1 {
  margin-left: 0.25rem;
}

.e-ml-10 {
  margin-left: 2.5rem;
}

.e-ml-2 {
  margin-left: 0.5rem;
}

.e-ml-3 {
  margin-left: 0.75rem;
}

.e-ml-4 {
  margin-left: 1rem;
}

.e-ml-5 {
  margin-left: 1.25rem;
}

.e-ml-6 {
  margin-left: 1.5rem;
}

.e-ml-auto {
  margin-left: auto;
}

.e-ml-px {
  margin-left: 1px;
}

.e-mr-0 {
  margin-right: 0px;
}

.e-mr-0\.5 {
  margin-right: 0.125rem;
}

.e-mr-1 {
  margin-right: 0.25rem;
}

.e-mr-2 {
  margin-right: 0.5rem;
}

.e-mr-3 {
  margin-right: 0.75rem;
}

.e-mr-4 {
  margin-right: 1rem;
}

.e-mr-6 {
  margin-right: 1.5rem;
}

.e-mt-0\.5 {
  margin-top: 0.125rem;
}

.e-mt-1 {
  margin-top: 0.25rem;
}

.e-mt-1\.5 {
  margin-top: 0.375rem;
}

.e-mt-2 {
  margin-top: 0.5rem;
}

.e-mt-4 {
  margin-top: 1rem;
}

.e-mt-6 {
  margin-top: 1.5rem;
}

.e-mt-8 {
  margin-top: 2rem;
}

.e-box-border {
  box-sizing: border-box;
}

.e-box-content {
  box-sizing: content-box;
}

.e-line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.e-line-clamp-15 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 15;
}

.e-line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.e-line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.e-line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.e-line-clamp-5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.e-line-clamp-6 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.e-block {
  display: block;
}

.e-inline-block {
  display: inline-block;
}

.e-inline {
  display: inline;
}

.e-flex {
  display: flex;
}

.e-inline-flex {
  display: inline-flex;
}

.e-grid {
  display: grid;
}

.e-list-item {
  display: list-item;
}

.e-hidden {
  display: none;
}

.e-h-0 {
  height: 0px;
}

.e-h-0\.5 {
  height: 0.125rem;
}

.e-h-1 {
  height: 0.25rem;
}

.e-h-10 {
  height: 2.5rem;
}

.e-h-11 {
  height: 2.75rem;
}

.e-h-12 {
  height: 3rem;
}

.e-h-14 {
  height: 3.5rem;
}

.e-h-16 {
  height: 4rem;
}

.e-h-18 {
  height: 4.5rem;
}

.e-h-2 {
  height: 0.5rem;
}

.e-h-20 {
  height: 5rem;
}

.e-h-28 {
  height: 7rem;
}

.e-h-3 {
  height: 0.75rem;
}

.e-h-3\.5 {
  height: 0.875rem;
}

.e-h-30 {
  height: 7.5rem;
}

.e-h-36 {
  height: 9rem;
}

.e-h-4 {
  height: 1rem;
}

.e-h-48 {
  height: 12rem;
}

.e-h-5 {
  height: 1.25rem;
}

.e-h-5\/6 {
  height: 83.333333%;
}

.e-h-6 {
  height: 1.5rem;
}

.e-h-64 {
  height: 16rem;
}

.e-h-7 {
  height: 1.75rem;
}

.e-h-8 {
  height: 2rem;
}

.e-h-80 {
  height: 20rem;
}

.e-h-\[390px\] {
  height: 390px;
}

.e-h-\[60px\] {
  height: 60px;
}

.e-h-auto {
  height: auto;
}

.e-h-full {
  height: 100%;
}

.e-h-px {
  height: 1px;
}

.e-max-h-14 {
  max-height: 3.5rem;
}

.e-max-h-40 {
  max-height: 10rem;
}

.e-max-h-80 {
  max-height: 20rem;
}

.e-max-h-\[1024px\] {
  max-height: 1024px;
}

.e-max-h-\[300px\] {
  max-height: 300px;
}

.e-max-h-\[90\%\] {
  max-height: 90%;
}

.e-max-h-full {
  max-height: 100%;
}

.e-max-h-screen {
  max-height: 100vh;
}

.e-min-h-10 {
  min-height: 2.5rem;
}

.e-min-h-12 {
  min-height: 3rem;
}

.e-min-h-20 {
  min-height: 5rem;
}

.e-min-h-\[112px\] {
  min-height: 112px;
}

.e-min-h-full {
  min-height: 100%;
}

.e-w-0 {
  width: 0px;
}

.e-w-0\.5 {
  width: 0.125rem;
}

.e-w-1\/2 {
  width: 50%;
}

.e-w-1\/3 {
  width: 33.333333%;
}

.e-w-10 {
  width: 2.5rem;
}

.e-w-10\/12 {
  width: 83.333333%;
}

.e-w-100 {
  width: 26rem;
}

.e-w-12 {
  width: 3rem;
}

.e-w-16 {
  width: 4rem;
}

.e-w-162 {
  width: 40.5rem;
}

.e-w-18 {
  width: 4.5rem;
}

.e-w-2 {
  width: 0.5rem;
}

.e-w-20 {
  width: 5rem;
}

.e-w-24 {
  width: 6rem;
}

.e-w-28 {
  width: 7rem;
}

.e-w-3 {
  width: 0.75rem;
}

.e-w-3\.5 {
  width: 0.875rem;
}

.e-w-3\/4 {
  width: 75%;
}

.e-w-30 {
  width: 7.5rem;
}

.e-w-36 {
  width: 9rem;
}

.e-w-4 {
  width: 1rem;
}

.e-w-40 {
  width: 10rem;
}

.e-w-42 {
  width: 10.5rem;
}

.e-w-44 {
  width: 11rem;
}

.e-w-5 {
  width: 1.25rem;
}

.e-w-52 {
  width: 13rem;
}

.e-w-56 {
  width: 14rem;
}

.e-w-6 {
  width: 1.5rem;
}

.e-w-64 {
  width: 16rem;
}

.e-w-7 {
  width: 1.75rem;
}

.e-w-72 {
  width: 18rem;
}

.e-w-8 {
  width: 2rem;
}

.e-w-81 {
  width: 20.25rem;
}

.e-w-96 {
  width: 24rem;
}

.e-w-\[390px\] {
  width: 390px;
}

.e-w-auto {
  width: auto;
}

.e-w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.e-w-full {
  width: 100%;
}

.e-w-max {
  width: -moz-max-content;
  width: max-content;
}

.e-w-px {
  width: 1px;
}

.e-w-screen {
  width: 100vw;
}

.e-min-w-0 {
  min-width: 0px;
}

.e-min-w-100 {
  min-width: 26rem;
}

.e-min-w-128 {
  min-width: 32rem;
}

.e-min-w-24 {
  min-width: 6rem;
}

.e-min-w-28 {
  min-width: 7rem;
}

.e-min-w-30 {
  min-width: 7.5rem;
}

.e-min-w-4 {
  min-width: 1rem;
}

.e-min-w-42 {
  min-width: 10.5rem;
}

.e-min-w-5 {
  min-width: 1.25rem;
}

.e-min-w-8 {
  min-width: 2rem;
}

.e-min-w-81 {
  min-width: 20.25rem;
}

.e-min-w-96 {
  min-width: 24rem;
}

.e-min-w-fit {
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.\!e-max-w-max {
  max-width: -moz-max-content !important;
  max-width: max-content !important;
}

.e-max-w-36 {
  max-width: 9rem;
}

.e-max-w-4xl {
  max-width: 56rem;
}

.e-max-w-7xl {
  max-width: 80rem;
}

.e-max-w-\[220px\] {
  max-width: 220px;
}

.e-max-w-full {
  max-width: 100%;
}

.e-max-w-lg {
  max-width: 32rem;
}

.e-max-w-md {
  max-width: 28rem;
}

.e-max-w-none {
  max-width: none;
}

.e-max-w-sm {
  max-width: 24rem;
}

.e-max-w-xl {
  max-width: 36rem;
}

.e-max-w-xs {
  max-width: 20rem;
}

.e-flex-1 {
  flex: 1 1 0%;
}

.e-flex-auto {
  flex: 1 1 auto;
}

.e-flex-initial {
  flex: 0 1 auto;
}

.e-flex-none {
  flex: none;
}

.e-flex-shrink-0 {
  flex-shrink: 0;
}

.e-shrink-0 {
  flex-shrink: 0;
}

.e-flex-grow {
  flex-grow: 1;
}

.e-flex-grow-0 {
  flex-grow: 0;
}

.e-grow {
  flex-grow: 1;
}

.e-grow-0 {
  flex-grow: 0;
}

.-e-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-e-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-e-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.e-translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.e-translate-x-4 {
  --tw-translate-x: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.e-translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.e-translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.e-translate-y-6 {
  --tw-translate-y: 1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.e-translate-y-full {
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-e-rotate-0 {
  --tw-rotate: -0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-e-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.e-rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.e-rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.e-rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.e-rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.e-transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes e-pulse {
  50% {
    opacity: .5;
  }
}

.e-animate-pulse {
  animation: e-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes e-skeleton {
  0%, 100% {
    background: #23272A;
  }

  50% {
    background: #2C3135;
  }
}

.e-animate-skeleton {
  animation: e-skeleton 2s ease-in-out infinite;
}

@keyframes e-spin {
  to {
    transform: rotate(360deg);
  }
}

.e-animate-spin {
  animation: e-spin 1s linear infinite;
}

.e-cursor-default {
  cursor: default;
}

.e-cursor-not-allowed {
  cursor: not-allowed;
}

.e-cursor-pointer {
  cursor: pointer;
}

.e-cursor-text {
  cursor: text;
}

.e-touch-none {
  touch-action: none;
}

.e-select-none {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.e-resize-none {
  resize: none;
}

.e-resize-y {
  resize: vertical;
}

.e-resize-x {
  resize: horizontal;
}

.e-resize {
  resize: both;
}

.e-scroll-m-0 {
  scroll-margin: 0px;
}

.e-scroll-p-0 {
  scroll-padding: 0px;
}

.e-appearance-none {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.e-grid-flow-col {
  grid-auto-flow: column;
}

.e-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.e-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.e-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.e-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.e-grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.e-grid-cols-\[auto_1fr\] {
  grid-template-columns: auto 1fr;
}

.e-flex-row {
  flex-direction: row;
}

.e-flex-row-reverse {
  flex-direction: row-reverse;
}

.e-flex-col {
  flex-direction: column;
}

.e-flex-wrap {
  flex-wrap: wrap;
}

.e-flex-nowrap {
  flex-wrap: nowrap;
}

.e-content-center {
  align-content: center;
}

.e-content-between {
  align-content: space-between;
}

.e-items-start {
  align-items: flex-start;
}

.e-items-end {
  align-items: flex-end;
}

.e-items-center {
  align-items: center;
}

.e-justify-start {
  justify-content: flex-start;
}

.e-justify-end {
  justify-content: flex-end;
}

.e-justify-center {
  justify-content: center;
}

.e-justify-between {
  justify-content: space-between;
}

.e-justify-around {
  justify-content: space-around;
}

.e-justify-evenly {
  justify-content: space-evenly;
}

.e-justify-items-start {
  justify-items: start;
}

.e-justify-items-center {
  justify-items: center;
}

.e-gap-0\.5 {
  gap: 0.125rem;
}

.e-gap-1 {
  gap: 0.25rem;
}

.e-gap-1\.5 {
  gap: 0.375rem;
}

.e-gap-2 {
  gap: 0.5rem;
}

.e-gap-2\.5 {
  gap: 0.625rem;
}

.e-gap-3 {
  gap: 0.75rem;
}

.e-gap-4 {
  gap: 1rem;
}

.e-gap-5 {
  gap: 1.25rem;
}

.e-gap-6 {
  gap: 1.5rem;
}

.e-gap-x-1 {
  -moz-column-gap: 0.25rem;
       column-gap: 0.25rem;
}

.e-gap-x-3 {
  -moz-column-gap: 0.75rem;
       column-gap: 0.75rem;
}

.e-gap-y-1 {
  row-gap: 0.25rem;
}

.e-gap-y-2 {
  row-gap: 0.5rem;
}

.e-gap-y-4 {
  row-gap: 1rem;
}

.e-gap-y-6 {
  row-gap: 1.5rem;
}

.-e-space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
}

.e-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.125rem * var(--tw-space-x-reverse));
  margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)));
}

.e-space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.e-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.e-space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.e-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.e-space-x-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.75rem * var(--tw-space-x-reverse));
  margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.e-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
}

.e-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.e-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.e-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.e-space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.e-space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.e-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.e-space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.e-self-start {
  align-self: flex-start;
}

.e-self-center {
  align-self: center;
}

.e-self-stretch {
  align-self: stretch;
}

.e-justify-self-center {
  justify-self: center;
}

.e-overflow-auto {
  overflow: auto;
}

.e-overflow-hidden {
  overflow: hidden;
}

.e-overflow-x-auto {
  overflow-x: auto;
}

.e-overflow-y-auto {
  overflow-y: auto;
}

.e-overflow-x-hidden {
  overflow-x: hidden;
}

.e-overflow-y-hidden {
  overflow-y: hidden;
}

.e-overflow-x-clip {
  overflow-x: clip;
}

.e-overflow-x-scroll {
  overflow-x: scroll;
}

.e-overflow-y-scroll {
  overflow-y: scroll;
}

.e-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-text-ellipsis {
  text-overflow: ellipsis;
}

.e-whitespace-nowrap {
  white-space: nowrap;
}

.e-break-all {
  word-break: break-all;
}

.e-rounded {
  border-radius: 0.25rem;
}

.e-rounded-2xl {
  border-radius: 1rem;
}

.e-rounded-full {
  border-radius: 9999px;
}

.e-rounded-lg {
  border-radius: 0.5rem;
}

.e-rounded-md {
  border-radius: 0.375rem;
}

.e-rounded-sm {
  border-radius: 0.125rem;
}

.e-rounded-xl {
  border-radius: 0.75rem;
}

.e-rounded-b-lg {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.e-rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.e-rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.e-rounded-bl-full {
  border-bottom-left-radius: 9999px;
}

.e-rounded-bl-lg {
  border-bottom-left-radius: 0.5rem;
}

.e-rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}

.e-rounded-ss {
  border-start-start-radius: 0.25rem;
}

.e-rounded-tl-full {
  border-top-left-radius: 9999px;
}

.e-rounded-tl-lg {
  border-top-left-radius: 0.5rem;
}

.e-rounded-tr-full {
  border-top-right-radius: 9999px;
}

.e-rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}

.e-border {
  border-width: 1px;
}

.e-border-0 {
  border-width: 0px;
}

.e-border-2 {
  border-width: 2px;
}

.e-border-x {
  border-left-width: 1px;
  border-right-width: 1px;
}

.e-border-x-2 {
  border-left-width: 2px;
  border-right-width: 2px;
}

.e-border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.e-border-y-2 {
  border-top-width: 2px;
  border-bottom-width: 2px;
}

.e-border-b {
  border-bottom-width: 1px;
}

.e-border-b-0 {
  border-bottom-width: 0px;
}

.e-border-b-4 {
  border-bottom-width: 4px;
}

.e-border-l {
  border-left-width: 1px;
}

.e-border-l-8 {
  border-left-width: 8px;
}

.e-border-r {
  border-right-width: 1px;
}

.e-border-t {
  border-top-width: 1px;
}

.e-border-t-0 {
  border-top-width: 0px;
}

.e-border-t-2 {
  border-top-width: 2px;
}

.e-border-solid {
  border-style: solid;
}

.e-border-dashed {
  border-style: dashed;
}

.e-border-none {
  border-style: none;
}

.e-border-ai-primary-100 {
  border-color: var(--ai-primary-100);
}

.e-border-creator-primary-50 {
  --tw-border-opacity: 1;
  border-color: rgb(253 241 224 / var(--tw-border-opacity, 1));
}

.e-border-error-400 {
  --tw-border-opacity: 1;
  border-color: rgb(255 125 129 / var(--tw-border-opacity, 1));
}

.e-border-error-500 {
  --tw-border-opacity: 1;
  border-color: rgb(255 92 97 / var(--tw-border-opacity, 1));
}

.e-border-error-900 {
  --tw-border-opacity: 1;
  border-color: rgb(92 33 35 / var(--tw-border-opacity, 1));
}

.e-border-neutral-300 {
  --tw-border-opacity: 1;
  border-color: rgb(129 132 135 / var(--tw-border-opacity, 1));
}

.e-border-neutral-400 {
  --tw-border-opacity: 1;
  border-color: rgb(86 90 93 / var(--tw-border-opacity, 1));
}

.e-border-neutral-50 {
  --tw-border-opacity: 1;
  border-color: rgb(238 239 239 / var(--tw-border-opacity, 1));
}

.e-border-neutral-500 {
  --tw-border-opacity: 1;
  border-color: rgb(44 49 53 / var(--tw-border-opacity, 1));
}

.e-border-neutral-600 {
  --tw-border-opacity: 1;
  border-color: rgb(35 39 42 / var(--tw-border-opacity, 1));
}

.e-border-neutral-700 {
  --tw-border-opacity: 1;
  border-color: rgb(26 29 32 / var(--tw-border-opacity, 1));
}

.e-border-neutral-800 {
  --tw-border-opacity: 1;
  border-color: rgb(21 23 25 / var(--tw-border-opacity, 1));
}

.e-border-neutral-900 {
  --tw-border-opacity: 1;
  border-color: rgb(16 18 19 / var(--tw-border-opacity, 1));
}

.e-border-primary-200 {
  --tw-border-opacity: 1;
  border-color: rgb(166 198 255 / var(--tw-border-opacity, 1));
}

.e-border-primary-400 {
  --tw-border-opacity: 1;
  border-color: rgb(57 129 255 / var(--tw-border-opacity, 1));
}

.e-border-primary-50 {
  --tw-border-opacity: 1;
  border-color: rgb(236 243 255 / var(--tw-border-opacity, 1));
}

.e-border-primary-500 {
  --tw-border-opacity: 1;
  border-color: rgb(7 98 255 / var(--tw-border-opacity, 1));
}

.e-border-primary-800 {
  --tw-border-opacity: 1;
  border-color: rgb(3 47 122 / var(--tw-border-opacity, 1));
}

.e-border-secondary-200 {
  --tw-border-opacity: 1;
  border-color: rgb(174 236 240 / var(--tw-border-opacity, 1));
}

.e-border-secondary-500 {
  --tw-border-opacity: 1;
  border-color: rgb(52 208 218 / var(--tw-border-opacity, 1));
}

.e-border-success-400 {
  --tw-border-opacity: 1;
  border-color: rgb(86 218 115 / var(--tw-border-opacity, 1));
}

.e-border-success-500 {
  --tw-border-opacity: 1;
  border-color: rgb(44 209 80 / var(--tw-border-opacity, 1));
}

.e-border-success-800 {
  --tw-border-opacity: 1;
  border-color: rgb(21 100 38 / var(--tw-border-opacity, 1));
}

.e-border-transparent {
  border-color: transparent;
}

.e-border-warning-500 {
  --tw-border-opacity: 1;
  border-color: rgb(251 191 36 / var(--tw-border-opacity, 1));
}

.e-border-warning-600 {
  --tw-border-opacity: 1;
  border-color: rgb(201 153 29 / var(--tw-border-opacity, 1));
}

.e-border-x-transparent {
  border-left-color: transparent;
  border-right-color: transparent;
}

.e-border-y-neutral-600 {
  --tw-border-opacity: 1;
  border-top-color: rgb(35 39 42 / var(--tw-border-opacity, 1));
  border-bottom-color: rgb(35 39 42 / var(--tw-border-opacity, 1));
}

.e-border-b-neutral-50\/25 {
  border-bottom-color: rgb(238 239 239 / 0.25);
}

.e-border-b-neutral-500 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(44 49 53 / var(--tw-border-opacity, 1));
}

.e-border-b-neutral-600 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(35 39 42 / var(--tw-border-opacity, 1));
}

.e-border-r-neutral-300 {
  --tw-border-opacity: 1;
  border-right-color: rgb(129 132 135 / var(--tw-border-opacity, 1));
}

.e-border-r-neutral-400 {
  --tw-border-opacity: 1;
  border-right-color: rgb(86 90 93 / var(--tw-border-opacity, 1));
}

.e-border-r-neutral-600 {
  --tw-border-opacity: 1;
  border-right-color: rgb(35 39 42 / var(--tw-border-opacity, 1));
}

.e-border-t-transparent {
  border-top-color: transparent;
}

.e-bg-\[\#101214\] {
  --tw-bg-opacity: 1;
  background-color: rgb(16 18 20 / var(--tw-bg-opacity, 1));
}

.e-bg-base-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}

.e-bg-base-black\/60 {
  background-color: rgb(0 0 0 / 0.6);
}

.e-bg-base-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.e-bg-creator-primary-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 232 156 / var(--tw-bg-opacity, 1));
}

.e-bg-error-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 92 97 / var(--tw-bg-opacity, 1));
}

.e-bg-error-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(204 74 78 / var(--tw-bg-opacity, 1));
}

.e-bg-error-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(152 55 58 / var(--tw-bg-opacity, 1));
}

.e-bg-error-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(122 44 46 / var(--tw-bg-opacity, 1));
}

.e-bg-error-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(92 33 35 / var(--tw-bg-opacity, 1));
}

.e-bg-neutral-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(179 181 182 / var(--tw-bg-opacity, 1));
}

.e-bg-neutral-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(129 132 135 / var(--tw-bg-opacity, 1));
}

.e-bg-neutral-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(86 90 93 / var(--tw-bg-opacity, 1));
}

.e-bg-neutral-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(238 239 239 / var(--tw-bg-opacity, 1));
}

.e-bg-neutral-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(44 49 53 / var(--tw-bg-opacity, 1));
}

.e-bg-neutral-500\/75 {
  background-color: rgb(44 49 53 / 0.75);
}

.e-bg-neutral-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(35 39 42 / var(--tw-bg-opacity, 1));
}

.e-bg-neutral-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(26 29 32 / var(--tw-bg-opacity, 1));
}

.e-bg-neutral-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 23 25 / var(--tw-bg-opacity, 1));
}

.e-bg-neutral-800\/80 {
  background-color: rgb(21 23 25 / 0.8);
}

.e-bg-neutral-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(16 18 19 / var(--tw-bg-opacity, 1));
}

.e-bg-primary-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(7 98 255 / var(--tw-bg-opacity, 1));
}

.e-bg-primary-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(4 58 152 / var(--tw-bg-opacity, 1));
}

.e-bg-primary-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(3 47 122 / var(--tw-bg-opacity, 1));
}

.e-bg-primary-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(7 16 33 / var(--tw-bg-opacity, 1));
}

.e-bg-primary-900\/80 {
  background-color: rgb(7 16 33 / 0.8);
}

.e-bg-secondary-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(174 236 240 / var(--tw-bg-opacity, 1));
}

.e-bg-secondary-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(36 114 119 / var(--tw-bg-opacity, 1));
}

.e-bg-secondary-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(13 27 28 / var(--tw-bg-opacity, 1));
}

.e-bg-success-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(44 209 80 / var(--tw-bg-opacity, 1));
}

.e-bg-success-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 100 38 / var(--tw-bg-opacity, 1));
}

.e-bg-success-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 43 9 / var(--tw-bg-opacity, 1));
}

.e-bg-transparent {
  background-color: transparent;
}

.e-bg-warning-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 191 36 / var(--tw-bg-opacity, 1));
}

.e-bg-warning-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(201 153 29 / var(--tw-bg-opacity, 1));
}

.e-bg-warning-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(120 91 17 / var(--tw-bg-opacity, 1));
}

.e-bg-warning-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(91 69 13 / var(--tw-bg-opacity, 1));
}

.e-bg-opacity-10 {
  --tw-bg-opacity: 0.1;
}

.e-bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.e-bg-gradient-to-l {
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
}

.e-bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.e-bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.e-from-base-black {
  --tw-gradient-from: #000000 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.e-from-neutral-600 {
  --tw-gradient-from: #23272A var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(35 39 42 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.e-from-neutral-800 {
  --tw-gradient-from: #151719 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(21 23 25 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.e-from-neutral-900 {
  --tw-gradient-from: #101213 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(16 18 19 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.e-from-primary-400 {
  --tw-gradient-from: #3981FF var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(57 129 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.e-from-primary-900 {
  --tw-gradient-from: #071021 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(7 16 33 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.e-from-transparent {
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.e-from-80\% {
  --tw-gradient-from-position: 80%;
}

.e-via-neutral-400 {
  --tw-gradient-to: rgb(86 90 93 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #565A5D var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.e-to-neutral-700 {
  --tw-gradient-to: #1A1D20 var(--tw-gradient-to-position);
}

.e-to-neutral-900 {
  --tw-gradient-to: #101213 var(--tw-gradient-to-position);
}

.e-to-primary-900 {
  --tw-gradient-to: #071021 var(--tw-gradient-to-position);
}

.e-to-transparent {
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.e-bg-clip-padding {
  background-clip: padding-box;
}

.e-bg-origin-content {
  background-origin: content-box;
}

.e-fill-base-white {
  fill: #FFFFFF;
}

.e-fill-creator-primary-100 {
  fill: #FFE89C;
}

.e-fill-neutral-200 {
  fill: #B3B5B6;
}

.e-fill-neutral-500 {
  fill: #2C3135;
}

.e-fill-neutral-600 {
  fill: #23272A;
}

.e-fill-primary-500 {
  fill: #0762FF;
}

.e-fill-primary-900 {
  fill: #071021;
}

.e-object-cover {
  -o-object-fit: cover;
     object-fit: cover;
}

.e-p-0 {
  padding: 0px;
}

.e-p-1 {
  padding: 0.25rem;
}

.e-p-1\.5 {
  padding: 0.375rem;
}

.e-p-2 {
  padding: 0.5rem;
}

.e-p-3 {
  padding: 0.75rem;
}

.e-p-3\.5 {
  padding: 0.875rem;
}

.e-p-4 {
  padding: 1rem;
}

.e-p-5 {
  padding: 1.25rem;
}

.e-p-6 {
  padding: 1.5rem;
}

.e-px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.e-px-0\.5 {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}

.e-px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.e-px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.e-px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.e-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.e-px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.e-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.e-px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.e-px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.e-py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.e-py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.e-py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.e-py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.e-py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.e-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.e-py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.e-py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.e-py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.e-pb-0 {
  padding-bottom: 0px;
}

.e-pb-2 {
  padding-bottom: 0.5rem;
}

.e-pb-3 {
  padding-bottom: 0.75rem;
}

.e-pb-4 {
  padding-bottom: 1rem;
}

.e-pb-6 {
  padding-bottom: 1.5rem;
}

.e-pb-7 {
  padding-bottom: 1.75rem;
}

.e-pb-8 {
  padding-bottom: 2rem;
}

.e-pl-0 {
  padding-left: 0px;
}

.e-pl-1 {
  padding-left: 0.25rem;
}

.e-pl-10 {
  padding-left: 2.5rem;
}

.e-pl-2 {
  padding-left: 0.5rem;
}

.e-pl-4 {
  padding-left: 1rem;
}

.e-pr-0 {
  padding-right: 0px;
}

.e-pr-1 {
  padding-right: 0.25rem;
}

.e-pr-10 {
  padding-right: 2.5rem;
}

.e-pr-2 {
  padding-right: 0.5rem;
}

.e-pr-3 {
  padding-right: 0.75rem;
}

.e-pr-4 {
  padding-right: 1rem;
}

.e-pr-5 {
  padding-right: 1.25rem;
}

.e-pr-6 {
  padding-right: 1.5rem;
}

.e-pt-0 {
  padding-top: 0px;
}

.e-pt-0\.5 {
  padding-top: 0.125rem;
}

.e-pt-1 {
  padding-top: 0.25rem;
}

.e-pt-2 {
  padding-top: 0.5rem;
}

.e-pt-4 {
  padding-top: 1rem;
}

.e-pt-5 {
  padding-top: 1.25rem;
}

.e-pt-6 {
  padding-top: 1.5rem;
}

.e-pt-7 {
  padding-top: 1.75rem;
}

.e-pt-8 {
  padding-top: 2rem;
}

.e-text-left {
  text-align: left;
}

.e-text-center {
  text-align: center;
}

.e-text-right {
  text-align: right;
}

.e-align-bottom {
  vertical-align: bottom;
}

.e-font-sans {
  font-family: Inter, sans-serif;
}

.e-text-2xs {
  font-size: 0.625rem;
  line-height: 0.75rem;
}

.e-text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.e-text-lg {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.e-text-sm {
  font-size: 0.875rem;
  line-height: 1.137rem;
}

.e-text-xl {
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.e-text-xs {
  font-size: 0.75rem;
  line-height: 0.975rem;
}

.e-font-bold {
  font-weight: 700;
}

.e-font-extra-light {
  font-weight: 200;
}

.e-font-light {
  font-weight: 300;
}

.e-font-medium {
  font-weight: 500;
}

.e-font-regular {
  font-weight: 400;
}

.e-font-semi-bold {
  font-weight: 600;
}

.e-capitalize {
  text-transform: capitalize;
}

.e-leading-5 {
  line-height: 1.25rem;
}

.e-leading-7 {
  line-height: 1.75rem;
}

.e-leading-\[normal\] {
  line-height: normal;
}

.e-leading-none {
  line-height: 1;
}

.e-leading-tight {
  line-height: 1.25;
}

.e-text-ai-primary-100 {
  color: var(--ai-primary-100);
}

.e-text-ai-primary-200 {
  color: var(--ai-primary-200);
}

.e-text-base-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.e-text-base-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.e-text-base-white\/30 {
  color: rgb(255 255 255 / 0.3);
}

.e-text-base-white\/80 {
  color: rgb(255 255 255 / 0.8);
}

.e-text-creator-primary-50 {
  --tw-text-opacity: 1;
  color: rgb(253 241 224 / var(--tw-text-opacity, 1));
}

.e-text-error-400 {
  --tw-text-opacity: 1;
  color: rgb(255 125 129 / var(--tw-text-opacity, 1));
}

.e-text-error-50 {
  --tw-text-opacity: 1;
  color: rgb(255 242 243 / var(--tw-text-opacity, 1));
}

.e-text-error-500 {
  --tw-text-opacity: 1;
  color: rgb(255 92 97 / var(--tw-text-opacity, 1));
}

.e-text-error-600 {
  --tw-text-opacity: 1;
  color: rgb(204 74 78 / var(--tw-text-opacity, 1));
}

.e-text-error-900 {
  --tw-text-opacity: 1;
  color: rgb(92 33 35 / var(--tw-text-opacity, 1));
}

.e-text-neutral-100 {
  --tw-text-opacity: 1;
  color: rgb(222 223 223 / var(--tw-text-opacity, 1));
}

.e-text-neutral-200 {
  --tw-text-opacity: 1;
  color: rgb(179 181 182 / var(--tw-text-opacity, 1));
}

.e-text-neutral-300 {
  --tw-text-opacity: 1;
  color: rgb(129 132 135 / var(--tw-text-opacity, 1));
}

.e-text-neutral-400 {
  --tw-text-opacity: 1;
  color: rgb(86 90 93 / var(--tw-text-opacity, 1));
}

.e-text-neutral-50 {
  --tw-text-opacity: 1;
  color: rgb(238 239 239 / var(--tw-text-opacity, 1));
}

.e-text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(44 49 53 / var(--tw-text-opacity, 1));
}

.e-text-neutral-900 {
  --tw-text-opacity: 1;
  color: rgb(16 18 19 / var(--tw-text-opacity, 1));
}

.e-text-primary-200 {
  --tw-text-opacity: 1;
  color: rgb(166 198 255 / var(--tw-text-opacity, 1));
}

.e-text-primary-300 {
  --tw-text-opacity: 1;
  color: rgb(107 161 255 / var(--tw-text-opacity, 1));
}

.e-text-primary-400 {
  --tw-text-opacity: 1;
  color: rgb(57 129 255 / var(--tw-text-opacity, 1));
}

.e-text-primary-50 {
  --tw-text-opacity: 1;
  color: rgb(236 243 255 / var(--tw-text-opacity, 1));
}

.e-text-primary-500 {
  --tw-text-opacity: 1;
  color: rgb(7 98 255 / var(--tw-text-opacity, 1));
}

.e-text-secondary-50 {
  --tw-text-opacity: 1;
  color: rgb(233 250 251 / var(--tw-text-opacity, 1));
}

.e-text-secondary-800 {
  --tw-text-opacity: 1;
  color: rgb(30 76 79 / var(--tw-text-opacity, 1));
}

.e-text-success-300 {
  --tw-text-opacity: 1;
  color: rgb(129 228 151 / var(--tw-text-opacity, 1));
}

.e-text-success-400 {
  --tw-text-opacity: 1;
  color: rgb(86 218 115 / var(--tw-text-opacity, 1));
}

.e-text-success-50 {
  --tw-text-opacity: 1;
  color: rgb(238 251 241 / var(--tw-text-opacity, 1));
}

.e-text-success-500 {
  --tw-text-opacity: 1;
  color: rgb(44 209 80 / var(--tw-text-opacity, 1));
}

.e-text-success-900 {
  --tw-text-opacity: 1;
  color: rgb(0 43 9 / var(--tw-text-opacity, 1));
}

.e-text-warning-200 {
  --tw-text-opacity: 1;
  color: rgb(254 232 176 / var(--tw-text-opacity, 1));
}

.e-text-warning-400 {
  --tw-text-opacity: 1;
  color: rgb(252 204 80 / var(--tw-text-opacity, 1));
}

.e-text-warning-50 {
  --tw-text-opacity: 1;
  color: rgb(255 250 238 / var(--tw-text-opacity, 1));
}

.e-text-warning-500 {
  --tw-text-opacity: 1;
  color: rgb(251 191 36 / var(--tw-text-opacity, 1));
}

.e-text-warning-600 {
  --tw-text-opacity: 1;
  color: rgb(201 153 29 / var(--tw-text-opacity, 1));
}

.e-text-warning-900 {
  --tw-text-opacity: 1;
  color: rgb(91 69 13 / var(--tw-text-opacity, 1));
}

.e-underline {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.e-underline-offset-2 {
  text-underline-offset: 2px;
}

.e-opacity-0 {
  opacity: 0;
}

.e-opacity-100 {
  opacity: 1;
}

.e-opacity-30 {
  opacity: 0.3;
}

.e-opacity-40 {
  opacity: 0.4;
}

.e-opacity-50 {
  opacity: 0.5;
}

.e-opacity-60 {
  opacity: 0.6;
}

.e-opacity-70 {
  opacity: 0.7;
}

.e-shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.e-shadow-center-sm {
  --tw-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.10);
  --tw-shadow-colored: 0 0 15px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.e-shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.e-shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.e-shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.e-shadow-toast {
  --tw-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.8);
  --tw-shadow-colored: 0px 4px 48px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.e-shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.e-shadow-base-black\/90 {
  --tw-shadow-color: rgb(0 0 0 / 0.9);
  --tw-shadow: var(--tw-shadow-colored);
}

.e-shadow-primary-500\/50 {
  --tw-shadow-color: rgb(7 98 255 / 0.5);
  --tw-shadow: var(--tw-shadow-colored);
}

.e-outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.e-outline-0 {
  outline-width: 0px;
}

.e-ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.e-ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.e-ring-neutral-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(44 49 53 / var(--tw-ring-opacity, 1));
}

.e-ring-primary-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(57 129 255 / var(--tw-ring-opacity, 1));
}

.e-ring-success-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(86 218 115 / var(--tw-ring-opacity, 1));
}

.e-ring-success-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(44 209 80 / var(--tw-ring-opacity, 1));
}

.e-ring-warning-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(252 204 80 / var(--tw-ring-opacity, 1));
}

.e-ring-warning-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(251 191 36 / var(--tw-ring-opacity, 1));
}

.e-drop-shadow-ai-normal {
  --tw-drop-shadow: drop-shadow(0px 0px 5px var(--ai-primary-200));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.e-drop-shadow-glow {
  --tw-drop-shadow: drop-shadow(0px 8px 15px rgb(255 255 255 / 1));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.e-drop-shadow-xl {
  --tw-drop-shadow: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.e-backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.e-transition {
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.e-transition-\[width\] {
  transition-property: width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.e-transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.e-transition-colors {
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.e-transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.e-transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.e-delay-\[200ms\] {
  transition-delay: 200ms;
}

.e-duration-100 {
  transition-duration: 100ms;
}

.e-duration-200 {
  transition-duration: 200ms;
}

.e-duration-300 {
  transition-duration: 300ms;
}

.e-duration-500 {
  transition-duration: 500ms;
}

.e-duration-700 {
  transition-duration: 700ms;
}

.e-duration-\[300ms\] {
  transition-duration: 300ms;
}

.e-ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.e-ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.e-ease-linear {
  transition-timing-function: linear;
}

.e-ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.e-scrollbar-w-0::-webkit-scrollbar {
  width: 0px;
}

.e-scrollbar-w-2::-webkit-scrollbar {
  width: 0.5rem;
}

.e-scrollbar-w-0.e-5::-webkit-scrollbar {
  width: 0.125rem;
}

.e-scrollbar-w-2.e-5::-webkit-scrollbar {
  width: 0.625rem;
}

.e-scrollbar-thumb-rounded-lg::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
}

.e-scrollbar-thumb-neutral-400::-webkit-scrollbar-thumb {
  background-color: #565A5D;
}

.e-scrollbar-thumb-neutral-600::-webkit-scrollbar-thumb {
  background-color: #23272A;
}

.e-scrollbar-track-neutral-700::-webkit-scrollbar-track {
  background-color: #1A1D20;
}

.e-scrollbar-track-neutral-900::-webkit-scrollbar-track {
  background-color: #101213;
}

.e-glow-50 {
  box-shadow: 0 0 25px 3px #FDF1E040;
  transition-duration: 500ms;
  transition-property: all;
}

.e-glow-100 {
  box-shadow: 0 0 25px 5px #FFE89C60;
  transition-duration: 500ms;
  transition-property: all;
}

.placeholder\:e-text-base::-moz-placeholder {
  font-size: 1rem;
  line-height: 1.5rem;
}

.placeholder\:e-text-base::placeholder {
  font-size: 1rem;
  line-height: 1.5rem;
}

.placeholder\:e-font-light::-moz-placeholder {
  font-weight: 300;
}

.placeholder\:e-font-light::placeholder {
  font-weight: 300;
}

.placeholder\:e-text-neutral-300::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(129 132 135 / var(--tw-text-opacity, 1));
}

.placeholder\:e-text-neutral-300::placeholder {
  --tw-text-opacity: 1;
  color: rgb(129 132 135 / var(--tw-text-opacity, 1));
}

.placeholder\:e-text-neutral-500::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(44 49 53 / var(--tw-text-opacity, 1));
}

.placeholder\:e-text-neutral-500::placeholder {
  --tw-text-opacity: 1;
  color: rgb(44 49 53 / var(--tw-text-opacity, 1));
}

.before\:e-absolute::before {
  content: var(--tw-content);
  position: absolute;
}

.before\:e-h-full::before {
  content: var(--tw-content);
  height: 100%;
}

.before\:e-w-full::before {
  content: var(--tw-content);
  width: 100%;
}

@keyframes e-translation-x {
  0% {
    content: var(--tw-content);
    transform: translateX(0px);
  }

  100% {
    content: var(--tw-content);
    transform: translateX(100%);
  }
}

.before\:e-animate-translation-x::before {
  content: var(--tw-content);
  animation: e-translation-x 1.5s ease-in-out infinite;
}

.before\:e-bg-creator-primary-100::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 232 156 / var(--tw-bg-opacity, 1));
}

.before\:e-bg-error-700::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(152 55 58 / var(--tw-bg-opacity, 1));
}

.before\:e-bg-neutral-500::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(44 49 53 / var(--tw-bg-opacity, 1));
}

.before\:e-bg-primary-700::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(4 58 152 / var(--tw-bg-opacity, 1));
}

.before\:e-content-\[\"\"\]::before {
  --tw-content: "";
  content: var(--tw-content);
}

.after\:e-absolute::after {
  content: var(--tw-content);
  position: absolute;
}

.after\:e-h-full::after {
  content: var(--tw-content);
  height: 100%;
}

.after\:e-w-full::after {
  content: var(--tw-content);
  width: 100%;
}

@keyframes e-translation-x {
  0% {
    content: var(--tw-content);
    transform: translateX(0px);
  }

  100% {
    content: var(--tw-content);
    transform: translateX(100%);
  }
}

.after\:e-animate-translation-x::after {
  content: var(--tw-content);
  animation: e-translation-x 1.5s ease-in-out infinite;
}

.after\:e-bg-creator-primary-100::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 232 156 / var(--tw-bg-opacity, 1));
}

.after\:e-bg-error-700::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(152 55 58 / var(--tw-bg-opacity, 1));
}

.after\:e-bg-neutral-500::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(44 49 53 / var(--tw-bg-opacity, 1));
}

.after\:e-bg-primary-700::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(4 58 152 / var(--tw-bg-opacity, 1));
}

.after\:e-content-\[\"\"\]::after {
  --tw-content: "";
  content: var(--tw-content);
}

.last\:e-pb-0:last-child {
  padding-bottom: 0px;
}

.checked\:e-border-4:checked {
  border-width: 4px;
}

.checked\:e-border-primary-500:checked {
  --tw-border-opacity: 1;
  border-color: rgb(7 98 255 / var(--tw-border-opacity, 1));
}

.checked\:e-border-transparent:checked {
  border-color: transparent;
}

.checked\:e-bg-base-white:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.checked\:e-bg-primary-500:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(7 98 255 / var(--tw-bg-opacity, 1));
}

.checked\:e-bg-\[url\(data\:image\/svg\+xml\;charset\=utf-8\;base64\2c PHN2ZyB2aWV3Qm94PScwIDAgMTYgMTYnIGZpbGw9JyNmZmYnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc\+PHBhdGggZD0nTTEyLjIwNyA0Ljc5M2ExIDEgMCAwIDEgMCAxLjQxNGwtNSA1YTEgMSAwIDAgMS0xLjQxNCAwbC0yLTJhMSAxIDAgMCAxIDEuNDE0LTEuNDE0TDYuNSA5LjA4Nmw0LjI5My00LjI5M2ExIDEgMCAwIDEgMS40MTQgMHonLz48L3N2Zz4\=\)\]:checked {
  background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB2aWV3Qm94PScwIDAgMTYgMTYnIGZpbGw9JyNmZmYnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTEyLjIwNyA0Ljc5M2ExIDEgMCAwIDEgMCAxLjQxNGwtNSA1YTEgMSAwIDAgMS0xLjQxNCAwbC0yLTJhMSAxIDAgMCAxIDEuNDE0LTEuNDE0TDYuNSA5LjA4Nmw0LjI5My00LjI5M2ExIDEgMCAwIDEgMS40MTQgMHonLz48L3N2Zz4=);
}

.checked\:e-bg-\[length\:100\%_100\%\]:checked {
  background-size: 100% 100%;
}

.checked\:e-bg-\[length\:120\%_120\%\]:checked {
  background-size: 120% 120%;
}

.checked\:e-bg-\[position\:50\%\]:checked {
  background-position: 50%;
}

.checked\:e-bg-no-repeat:checked {
  background-repeat: no-repeat;
}

.indeterminate\:e-border-transparent:indeterminate {
  border-color: transparent;
}

.indeterminate\:e-bg-primary-500:indeterminate {
  --tw-bg-opacity: 1;
  background-color: rgb(7 98 255 / var(--tw-bg-opacity, 1));
}

.indeterminate\:e-bg-\[url\(data\:image\/svg\+xml\;charset\=utf-8\;base64\2c PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIyIiB2aWV3Qm94PSIwIDAgOCAyIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTAgMS4wMDA2NUMwIDAuNjMyNDYxIDAuMjk4NDc3IDAuMzMzOTg0IDAuNjY2NjY3IDAuMzMzOTg0SDcuMzMzMzNDNy43MDE1MiAwLjMzMzk4NCA4IDAuNjMyNDYxIDggMS4wMDA2NUM4IDEuMzY4ODQgNy43MDE1MiAxLjY2NzMyIDcuMzMzMzMgMS42NjczMkgwLjY2NjY2N0MwLjI5ODQ3NyAxLjY2NzMyIDAgMS4zNjg4NCAwIDEuMDAwNjVaIiBmaWxsPSIjRUVFRkVGIi8\+Cjwvc3ZnPgo\=\)\]:indeterminate {
  background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIyIiB2aWV3Qm94PSIwIDAgOCAyIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTAgMS4wMDA2NUMwIDAuNjMyNDYxIDAuMjk4NDc3IDAuMzMzOTg0IDAuNjY2NjY3IDAuMzMzOTg0SDcuMzMzMzNDNy43MDE1MiAwLjMzMzk4NCA4IDAuNjMyNDYxIDggMS4wMDA2NUM4IDEuMzY4ODQgNy43MDE1MiAxLjY2NzMyIDcuMzMzMzMgMS42NjczMkgwLjY2NjY2N0MwLjI5ODQ3NyAxLjY2NzMyIDAgMS4zNjg4NCAwIDEuMDAwNjVaIiBmaWxsPSIjRUVFRkVGIi8+Cjwvc3ZnPgo=);
}

.indeterminate\:e-bg-\[position\:50\%_50\%\]:indeterminate {
  background-position: 50% 50%;
}

.indeterminate\:e-bg-no-repeat:indeterminate {
  background-repeat: no-repeat;
}

.hover\:e-cursor-pointer:hover {
  cursor: pointer;
}

.hover\:e-rounded:hover {
  border-radius: 0.25rem;
}

.hover\:e-border-neutral-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(86 90 93 / var(--tw-border-opacity, 1));
}

.hover\:e-bg-base-white\/10:hover {
  background-color: rgb(255 255 255 / 0.1);
}

.hover\:e-bg-base-white\/20:hover {
  background-color: rgb(255 255 255 / 0.2);
}

.hover\:e-bg-creator-primary-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(253 241 224 / var(--tw-bg-opacity, 1));
}

.hover\:e-bg-error-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(152 55 58 / var(--tw-bg-opacity, 1));
}

.hover\:e-bg-neutral-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(179 181 182 / var(--tw-bg-opacity, 1));
}

.hover\:e-bg-neutral-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(86 90 93 / var(--tw-bg-opacity, 1));
}

.hover\:e-bg-neutral-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(44 49 53 / var(--tw-bg-opacity, 1));
}

.hover\:e-bg-neutral-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(35 39 42 / var(--tw-bg-opacity, 1));
}

.hover\:e-bg-neutral-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(26 29 32 / var(--tw-bg-opacity, 1));
}

.hover\:e-bg-neutral-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(21 23 25 / var(--tw-bg-opacity, 1));
}

.hover\:e-bg-neutral-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(16 18 19 / var(--tw-bg-opacity, 1));
}

.hover\:e-bg-primary-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(6 78 204 / var(--tw-bg-opacity, 1));
}

.hover\:e-bg-success-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(21 100 38 / var(--tw-bg-opacity, 1));
}

.hover\:e-bg-opacity-20:hover {
  --tw-bg-opacity: 0.2;
}

.hover\:e-text-primary-200:hover {
  --tw-text-opacity: 1;
  color: rgb(166 198 255 / var(--tw-text-opacity, 1));
}

.hover\:e-text-primary-300:hover {
  --tw-text-opacity: 1;
  color: rgb(107 161 255 / var(--tw-text-opacity, 1));
}

.hover\:e-underline:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.hover\:e-outline-none:hover {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.hover\:e-outline-0:hover {
  outline-width: 0px;
}

.hover\:e-ring-primary-600:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 78 204 / var(--tw-ring-opacity, 1));
}

.hover\:e-ring-success-700:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(26 125 48 / var(--tw-ring-opacity, 1));
}

.hover\:e-ring-warning-700:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(150 114 21 / var(--tw-ring-opacity, 1));
}

.hover\:e-glow-100:hover {
  box-shadow: 0 0 25px 5px #FFE89C60;
  transition-duration: 500ms;
  transition-property: all;
}

.focus\:e-border-neutral-400:focus {
  --tw-border-opacity: 1;
  border-color: rgb(86 90 93 / var(--tw-border-opacity, 1));
}

.focus\:e-border-primary-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(7 98 255 / var(--tw-border-opacity, 1));
}

.focus\:e-outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:e-outline-primary-200:focus {
  outline-color: #A6C6FF;
}

.active\:e-border-primary-500:active {
  --tw-border-opacity: 1;
  border-color: rgb(7 98 255 / var(--tw-border-opacity, 1));
}

.active\:e-bg-neutral-400:active {
  --tw-bg-opacity: 1;
  background-color: rgb(86 90 93 / var(--tw-bg-opacity, 1));
}

.active\:e-bg-neutral-500:active {
  --tw-bg-opacity: 1;
  background-color: rgb(44 49 53 / var(--tw-bg-opacity, 1));
}

.active\:e-bg-neutral-600:active {
  --tw-bg-opacity: 1;
  background-color: rgb(35 39 42 / var(--tw-bg-opacity, 1));
}

.active\:e-bg-neutral-800:active {
  --tw-bg-opacity: 1;
  background-color: rgb(21 23 25 / var(--tw-bg-opacity, 1));
}

.disabled\:e-cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:e-opacity-50:disabled {
  opacity: 0.5;
}

.e-group:hover .group-hover\:e-block {
  display: block;
}

.e-group:hover .group-hover\:e-inline-flex {
  display: inline-flex;
}

.e-group:hover .group-hover\:e-text-base-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.e-group:hover .group-hover\:e-text-primary-100 {
  --tw-text-opacity: 1;
  color: rgb(216 230 255 / var(--tw-text-opacity, 1));
}

.e-group:hover .group-hover\:e-text-primary-300 {
  --tw-text-opacity: 1;
  color: rgb(107 161 255 / var(--tw-text-opacity, 1));
}

.data-\[disabled\=true\]\:e-pointer-events-none[data-disabled="true"] {
  pointer-events: none;
}

.data-\[state\=active\]\:\!e-border-b-neutral-50[data-state="active"] {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(238 239 239 / var(--tw-border-opacity, 1)) !important;
}

.data-\[state\=active\]\:e-border-b-primary-500[data-state="active"] {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(7 98 255 / var(--tw-border-opacity, 1));
}

.data-\[state\=inactive\]\:e-border-b-transparent[data-state="inactive"] {
  border-bottom-color: transparent;
}

.data-\[selected\=true\]\:e-bg-neutral-500[data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(44 49 53 / var(--tw-bg-opacity, 1));
}

.data-\[selected\=true\]\:e-bg-neutral-800[data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(21 23 25 / var(--tw-bg-opacity, 1));
}

.data-\[selected\=true\]\:e-text-neutral-50[data-selected="true"] {
  --tw-text-opacity: 1;
  color: rgb(238 239 239 / var(--tw-text-opacity, 1));
}

.data-\[state\=active\]\:e-text-base-white[data-state="active"] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.data-\[disabled\=true\]\:e-opacity-50[data-disabled="true"] {
  opacity: 0.5;
}

@media (min-width: 640px) {
  .sm\:e-w-auto {
    width: auto;
  }

  .sm\:e-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:e-flex-row {
    flex-direction: row;
  }

  .sm\:e-gap-4 {
    gap: 1rem;
  }

  .sm\:e-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:e-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
}

@media (min-width: 768px) {
  .md\:e-mb-10 {
    margin-bottom: 2.5rem;
  }

  .md\:e-mt-2 {
    margin-top: 0.5rem;
  }

  .md\:e-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:e-text-lg {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .md\:e-text-xl {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:e-mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .lg\:e-flex {
    display: flex;
  }

  .lg\:e-min-h-full {
    min-height: 100%;
  }

  .lg\:e-w-100 {
    width: 26rem;
  }

  .lg\:e-flex-grow-0 {
    flex-grow: 0;
  }

  .lg\:e-flex-row {
    flex-direction: row;
  }

  .lg\:e-flex-nowrap {
    flex-wrap: nowrap;
  }

  .lg\:e-justify-start {
    justify-content: flex-start;
  }

  .lg\:e-gap-5 {
    gap: 1.25rem;
  }

  .lg\:e-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:e-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
}

@media (min-width: 1280px) {
  .xl\:e-hidden {
    display: none;
  }

  .xl\:e-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:e-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }
}

.\[\&_svg\]\:e-pointer-events-none svg {
  pointer-events: none;
}

.\[\&_svg\]\:e-size-4 svg {
  width: 1rem;
  height: 1rem;
}

.\[\&_svg\]\:e-shrink-0 svg {
  flex-shrink: 0;
}
